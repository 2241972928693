import constants from '../../../../config/constants.js'

function roundToNearestMinuteOr30Seconds(isoTimestamp) {
  const date = new Date(isoTimestamp);

  // Get seconds and milliseconds
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();

  // Total seconds (including fractions)
  const totalSeconds = seconds + milliseconds / 1000;

  // Determine whether to round down to 0 or 30, or up to the next minute
  if (totalSeconds < 15) {
    date.setSeconds(0, 0); // Round to the start of the minute
  } else if (totalSeconds < 45) {
    date.setSeconds(30, 0); // Round to the 30-second mark
  } else {
    date.setSeconds(0, 0); // Round to the start of the next minute
    date.setMinutes(date.getMinutes() + 1); // Increment the minute
  }

  return date.toISOString();
}

function buildTemperatureDataArr(dataArr) {
  let result = []
  if (dataArr.length <= 0) return result // Not suitable for graphing. Return empty.

  for (let i = 0; i < dataArr.length; i++) {
    let relevantDataArr = []
    relevantDataArr.push(dataArr[i].datetime) // Index 0 will hold datetime!
    relevantDataArr.push(dataArr[i].tstat_read_data.zone_temp) // Index 1 will hold zone temp.
    relevantDataArr.push(dataArr[i].tstat_read_data.heat_set) // Index 2 will hold heat setpoint.
    relevantDataArr.push(dataArr[i].tstat_read_data.cool_set) // Index 3 will hold cool setpoint.

    /*
    if(dataArr[i].tstat_read_data.hasOwnProperty(constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR]) && typeof dataArr[i].tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR]] === "object"){
      var livedataCo2SensorObj = dataArr[i].tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR]];
      //console.log("livedataCo2SensorObj: ", livedataCo2SensorObj);

      if(livedataCo2SensorObj.hasOwnProperty(constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_TEMPERATURE])){
        //console.log('about to save co2: ', livedataCo2SensorObj[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_CO2]]);
        relevantDataArr.push(livedataCo2SensorObj[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_TEMPERATURE]]);
        //req.livedata.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES][] = req.body[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR][constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_TEMPERATURE];
      }
      else
        relevantDataArr.push(null);
    }
    else{
      relevantDataArr.push(null);
    }
    */

    relevantDataArr.push(dataArr[i].tstat_read_data.sht31_t1 === '-' ? null : dataArr[i].tstat_read_data.sht31_t1) // Index 4 will hold external sens 1.
    relevantDataArr.push(dataArr[i].tstat_read_data.sht31_t2 === '-' ? null : dataArr[i].tstat_read_data.sht31_t2) // Index 5 will hold external sens 2.
    relevantDataArr.push(dataArr[i].tstat_read_data.sht31_t3 === '-' ? null : dataArr[i].tstat_read_data.sht31_t3) // Index 6 will hold external sens 3.
    relevantDataArr.push(dataArr[i].tstat_read_data.sht31_t4 === '-' ? null : dataArr[i].tstat_read_data.sht31_t4) // Index 7 will hold external sens 4.

    result.push(relevantDataArr)
  }

  return result
}

function buildVoCDataArr(dataArr, config) {
  let result = []

  if (dataArr.length <= 0) {
    return result // Not suitable for graphing. Return empty
  }

  for (let i = 0; i < dataArr.length; i++) {
    let relevantDataArr = []
    relevantDataArr.push(dataArr[i].datetime) // Datetime.
    if (
      dataArr[i].tstat_read_data && 
      dataArr[i].tstat_read_data.hasOwnProperty('live_zn_1') &&
      typeof dataArr[i].tstat_read_data['live_zn_1'] === 'object'
    ) {
      var liveData = dataArr[i].tstat_read_data['live_zn_1']

      if (liveData.voc_index) {
        relevantDataArr.push(liveData.voc_index)
      } else {
        relevantDataArr.push(null)
      }
    }
    result.push(relevantDataArr)
  }
  return result
}

function buildSpsDataArr(dataArr) {
  let result = []

  if (dataArr.length <= 0) {
    return result // Not suitable for graphing. Return empty
  }

  for (let i = 0; i < dataArr.length; i++) {
    let relevantDataArr = []
    relevantDataArr.push(dataArr[i].datetime) // Datetime.
    if (
      dataArr[i].tstat_read_data && 
      dataArr[i].tstat_read_data.hasOwnProperty('live_zn_1') &&
      typeof dataArr[i].tstat_read_data['live_zn_1'] === 'object'
    ) {
      var liveData = dataArr[i].tstat_read_data['live_zn_1']

      if (liveData.sps && liveData.sps) {
        relevantDataArr.push(liveData.sps.mc_2p5)
        relevantDataArr.push(liveData.sps.nc_2p5)
      } else {
        relevantDataArr.push(null)
        relevantDataArr.push(null)
      }
    }
    result.push(relevantDataArr)
  }
  return result
}

function buildCo2DataArr(dataArr, config) {
  let result = []

  console.log('buildCo2DataArr', dataArr)

  if (dataArr.length <= 0) {
    return result // Not suitable for graphing. Return empty
  }

  for (let i = 0; i < dataArr.length; i++) {
    let relevantDataArr = []
    relevantDataArr.push(dataArr[i].datetime) // Datetime.

    //SCD CO2 SENSOR
    //console.log("dataArr[i].tstat_read_data: " + JSON.stringify(dataArr[i].tstat_read_data));
    const _SCD = constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR
    const prop = constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[_SCD]
    
    if (
      dataArr[i].tstat_read_data.hasOwnProperty('live_zn_1') &&
      typeof dataArr[i].tstat_read_data['live_zn_1'] === 'object'
    ) {
      var livedataCo2SensorObj = dataArr[i].tstat_read_data['live_zn_1']
      //console.log("livedataCo2SensorObj: ", livedataCo2SensorObj);

      if (livedataCo2SensorObj.hasOwnProperty('co2')) {
        //console.log('about to save co2: ', livedataCo2SensorObj[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_CO2]]);
        relevantDataArr.push(
          livedataCo2SensorObj['co2'],
        )
        //req.livedata.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES][] = req.body[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR][constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_TEMPERATURE];
      } else relevantDataArr.push(null)
    } else {
      relevantDataArr.push(null)
    }
    
    //relevantDataArr.push(dataArr[i].tstat_read_data.co2_1 === "-" ? null : dataArr[i].tstat_read_data.co2_2);
    if (dataArr[i].tstat_read_data && dataArr[i].tstat_read_data.live_zn_2 && dataArr[i].tstat_read_data.live_zn_2.co2) {
      relevantDataArr.push(dataArr[i].tstat_read_data.live_zn_2.co2)
    } else {
      relevantDataArr.push(null)
    }

    //relevantDataArr.push(dataArr[i].tstat_read_data.co2_1 === "-" ? null : dataArr[i].tstat_read_data.co2_3);
    if (dataArr[i].tstat_read_data && dataArr[i].tstat_read_data.live_zn_3 && dataArr[i].tstat_read_data.live_zn_3.co2) {
      relevantDataArr.push(dataArr[i].tstat_read_data.live_zn_3.co2)
    } else {
      relevantDataArr.push(null)
    }
    //relevantDataArr.push(dataArr[i].tstat_read_data.co2_1 === "-" ? null : dataArr[i].tstat_read_data.co2_4);
    
    if (dataArr[i].tstat_read_data && dataArr[i].tstat_read_data.live_zn_4 && dataArr[i].tstat_read_data.live_zn_4.co2) {
      relevantDataArr.push(dataArr[i].tstat_read_data.live_zn_4.co2)
    } else {
      relevantDataArr.push(null)
    }

    // console.log('constants.THERMOSTAT_COMMAND_KEYS_OBJ.DUTY', constants.THERMOSTAT_COMMAND_KEYS_OBJ.DUTY)

    // DUTY
    if (
      dataArr[i].tstat_read_data.hasOwnProperty(
        constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.DUTY],
      ) &&
      typeof dataArr[i].tstat_read_data[
        constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.DUTY]
      ] === 'string'
    ) {
      relevantDataArr.push(
        dataArr[i].tstat_read_data[
          constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.DUTY]
        ],
      )
    } else {
      relevantDataArr.push(null)
    }

    const config = dataArr[i].cnfg_dcv
    if (config) {
      relevantDataArr.push(config.set_point || null)
      relevantDataArr.push(config.low_ppm || null)
      relevantDataArr.push(config.high_ppm || null)
      relevantDataArr.push(config.min_vent || null)
      relevantDataArr.push(config.max_vent || null)
    }

    result.push(relevantDataArr)
  }

  return result
}

/*
/^W1:([01]), W2:([01]), Y1:([01]), Y2:([01]), O:([01]), B:([01]), G:([01]), H:([01]), D:([01])$/;

W1:([01]), // 1
W2:([01]), // 2
Y1:([01]), // 3
Y2:([01]), // 4
O:([01]), // 5
B:([01]), // 6
G:([01]), // 7
H:([01]), // 8
D:([01])$/; // 9

{
  relay1: matchAll[1],
  relay2: matchAll[2],
  relay3: matchAll[3],
  relay4: matchAll[4],
  relay5: matchAll[5],
  relay6: matchAll[6],
  relay7: matchAll[7],
  relay8: matchAll[8],
  relay9: matchAll[9],
}
*/


//  DataArr is the unfiltered list of live data across a time period.
//    From it, we read relay states.
//  DeviceRelayConfig tells us the order of the relays. There are seven.
//    They are: Y (Cool Stage 1), Y2 (Cool Stage 2), W (Heat Stage 1), W2 (Heat Stage 2), G (Fan), O, B.
//    They could be in any order. We need to put them into a predictable
//    order so we can graph them. Except O and B, which we ignore.
function buildRelayDataArr(dataArr, deviceRelayConfig) {
  let result = []
  if (dataArr.length <= 1) return result // Not suitable for graphing. Return empty.

  for (let i = 0; i < dataArr.length; i++) {
    let relevantDataArr = []
    relevantDataArr.push(dataArr[i].datetime) // Index 0 will hold datetime!
    //  Indices 1 through 7 will hold relays: Y, Y2, W, W2, G.

    //W1:0, W2:0, Y1:0, Y2:0, O:0, B:0, G:1, H:0, D:0
    //  Y
    //let key = findKeyOfRelayNameValue(deviceRelayConfig, "Y");
    let key = 'relay5'
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(2)
    } else {
      relevantDataArr.push(4)
    }

    //  Y2
    //key = findKeyOfRelayNameValue(deviceRelayConfig, "Y2");
    key = 'relay6'
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(6)
    } else {
      relevantDataArr.push(8)
    }

    //  W
    //key = findKeyOfRelayNameValue(deviceRelayConfig, "W");
    key = 'relay3' 
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(10)
    } else {
      relevantDataArr.push(12)
    }

    //  W2
    //key = findKeyOfRelayNameValue(deviceRelayConfig, "W2");
    key = 'relay4'
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(14)
    } else {
      relevantDataArr.push(16)
    }

    //  G
    //key = findKeyOfRelayNameValue(deviceRelayConfig, "G");
    key = 'relay1' // 'relay7'
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(18)
    } else {
      relevantDataArr.push(20)
    }


    key = 'relay2' // 'relay5'
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(22)
    } else {
      relevantDataArr.push(24)
    }


    key = 'relay7'
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(26)
    } else {
      relevantDataArr.push(28)
    }

    result.push(relevantDataArr)
  }

  console.log('relay result', result)

  return result
}

//  DeviceRelayConfig: the object that tells us the order of the relays.
//    It has keys of the format rl_1 through rl_7.
//  NameValue: The relay we are searching for. It is either
//    Y, Y2, W, W2, or G.
//  The output: a key that corresponds with the format used
//    for relay states in liveData. Format is relay1 through relay7.
function findKeyOfRelayNameValue(deviceRelayConfig, nameValue) {
  // key is just a string that represents a relay i.e. "Y"
  if (deviceRelayConfig['rl_1'] === nameValue) {
    return 'relay1'
  } else if (deviceRelayConfig['rl_2'] === nameValue) {
    return 'relay2'
  } else if (deviceRelayConfig['rl_3'] === nameValue) {
    return 'relay3'
  } else if (deviceRelayConfig['rl_4'] === nameValue) {
    return 'relay4'
  } else if (deviceRelayConfig['rl_5'] === nameValue) {
    return 'relay5'
  } else if (deviceRelayConfig['rl_6'] === nameValue) {
    return 'relay6'
  } else if (deviceRelayConfig['rl_7'] === nameValue) {
    return 'relay7'
  }
}

const data = {
  roundToNearestMinuteOr30Seconds: roundToNearestMinuteOr30Seconds,
  buildTemperatureDataArr: buildTemperatureDataArr,
  buildCo2DataArr: buildCo2DataArr,
  buildRelayDataArr: buildRelayDataArr,
  buildVoCDataArr: buildVoCDataArr,
  buildSpsDataArr: buildSpsDataArr
}

export default data
